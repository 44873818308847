<template>
  <Page id="terms">
    <template v-slot:content>
      <Section>
        <SectionHeader :title="$t('pages.TermsConditionsPage.title')" />
        <div class="terms-container">
          <div v-for="section in sections" :key="section" class="terms-block">
            <Subtitle
              :label="$t(`pages.TermsConditionsPage.${section}.title`)"
            />
            <TextLabel
              :label="$t(`pages.TermsConditionsPage.${section}.textLabel`)"
            />
          </div>
        </div>
      </Section>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import {
  SectionHeader,
  Subtitle,
  TextLabel,
  Section,
} from "@sc/ds-ui-component-library";
export default {
  name: "TermsConditionsPage",
  components: {
    Page,
    SectionHeader,
    Subtitle,
    TextLabel,
    Section,
  },
  metaInfo() {
    return {
      title: this.$t("meta.terms.title"),
      meta: [
        { charset: "utf-8" },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.terms.description"),
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$t("meta.terms.keywords"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.terms.title"),
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.terms.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: this.$t("meta.terms.image"),
        },
      ],
    };
  },
  data() {
    return {
      sections: [
        "section1",
        "section2",
        "section3",
        "section4",
        "section5",
        "section6",
        "section7",
        "section8",
      ],
    };
  },
  mounted() {
    this.$meta().refresh();
  },
};
</script>

<style scoped lang="scss">
#terms {
  --section-header-width: 100%;
  --section-header-text-align: center;
  --section-header-margin: 0 0 64px 0;
  --section-bg-color: var(--color-7);
}

.terms-block,
.terms-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: justify;
  white-space: pre-line;
}

.terms-container {
  gap: 32px;
}

@media (max-width: 1024px) {
  #terms {
    --section-padding: 0px 16px 30px 16px;
    --section-header-width: 100%;
    --section-header-margin: 30px auto;

    ::v-deep {
      .btn {
        width: 100%;
        --button-justify-content: center;
      }
    }
  }
}

@media (max-width: 768px) {
  ::v-deep .title {
    --title-font-size: var(--title-3-font-size);
    --title-line-height: var(--title-3-line-height);
    --title-weight: var(--font-weight-bold);
  }

  #terms {
    --section-header-width: 100%;
  }
}
</style>
